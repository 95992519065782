import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Snackbar } from "@mui/material";
import { Clipboard } from "@capacitor/clipboard";
// types
import type { ButtonProps } from "@mui/material";

type Props = {
	password: string;
	copyMsg: string;
	buttonProps: Omit<ButtonProps, "id" | "disabled" | "onClick">;
};

const PasswordCopy = ({ password, copyMsg, buttonProps }: Props) => {
	const { t } = useTranslation();

	const [showCopySnackbar, setShowCopySnackbar] = useState(false);

	const copyPassword = useCallback(async () => {
		try {
			await Clipboard.write({ string: password });
			setShowCopySnackbar(true);
		} catch (error) {
			console.warn("clipboard write failed", error);
		}
	}, [password]);

	return (
		<>
			<Button id="btn-pwd-copy" disabled={password.length === 0} onClick={copyPassword} {...buttonProps}>
				{t("devices.guestPasswords.copyButton")}
			</Button>
			<Snackbar
				open={showCopySnackbar}
				message={copyMsg}
				autoHideDuration={3000}
				onClose={() => (setShowCopySnackbar(false))}
			/>
		</>
	);
};

PasswordCopy.defaultProps = {
	password: "",
	buttonProps: {},
};

PasswordCopy.propTypes = {
	password: PropTypes.string,
	copyMsg: PropTypes.string.isRequired,
	buttonProps: PropTypes.object,
};

export default PasswordCopy;
