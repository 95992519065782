import { forwardRef } from "react";
import InlineSvg from "react-inlinesvg";
// services
import { images } from "@local/theme";
// types
import type { Props as SVGProps } from "react-inlinesvg";

const DEFAULT_STYLE = {} as const satisfies SVGProps["style"];

const SIZES = {
	small: 24,
	medium: 30,
	large: 40,
} as const satisfies Record<string, number>;

type Props = SVGProps & {
	size?: keyof (typeof SIZES) | number; // TODO: remove "number" fallback
};

const Svg = forwardRef<SVGElement, Props>(({src, size = undefined, color = undefined, style = DEFAULT_STYLE, ...other}, ref) => {
	const _src = images(src);
	if (_src === undefined) {
		return null;
	}

	const _style = (color === undefined) ? style : {...style, color: color};

	if (size) {
		const _size = (typeof size === "number") ? size : SIZES[size];

		return (
			<InlineSvg
				innerRef={ref}
				src={_src}
				width={_size}
				height={_size}
				style={_style}
				{...other}
			/>
		);
	}

	return (
		<InlineSvg innerRef={ref} src={_src} style={_style} {...other} />
	);
});

if (process.env.NODE_ENV !== "production") {
	Svg.displayName = "SVG";
}

export default Svg;
