import { useTheme } from "@mui/material/styles";
// types
import type { CSSProperties, ReactNode } from "react";

const DEFAULT_STYLE = {} as const satisfies CSSProperties;

type Props = {
	title: string;
	subTitle?: ReactNode;
	titleWidth?: number;
	styleLeft?: CSSProperties;
	styleRight?: CSSProperties;
	children: ReactNode;
};

const RowEntry = ({
	title,
	subTitle = undefined,
	titleWidth = 40,
	styleLeft = DEFAULT_STYLE,
	styleRight = DEFAULT_STYLE,
	children,
}: Props) => {
	const theme = useTheme();

	return (
		<div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "16px"}}>
			<div style={{width: `calc(${titleWidth}% - 24px)`, minWidth: "80px", paddingRight: "24px", ...styleLeft}}>
				{(subTitle === undefined) ? title :
					<>
						<div>{title}</div>
						<div style={{marginTop: "2px", fontSize: "14px", color: theme.palette.text.secondary}}>{subTitle}</div>
					</>
				}
			</div>
			<div style={{width: `${100 - titleWidth}%`, ...styleRight}}>
				{children}
			</div>
		</div>
	);
};

export default RowEntry;
